<template>
  <modal
    transition="nice-modal-fade"
    width="400"
    :adaptive="true"
    :reset="true"
    height="auto"
    :name="modalName"
    class="modal--takeover"
    @before-close="beforeClose"
  >
    <div class="modal--wrapper" v-if="detail">
      <button type="button" class="close--button" @click="close">
        <img src="@/assets/img/icons/close-1.svg" />
      </button>
      <div class="main--image">
        <img :src="detail.image" @click="openLink" />
      </div>
      <div class="main--button">
        <button class="btn btn-primary" @click="openLink">
          {{ detail.button_name ? detail.button_name : $t('banner.getBanner') }}
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
import HelperMixin from '@/mixins/helpers';
import { mapState } from 'vuex';
import Cookies from 'js-cookie';

export default {
  mixins: [HelperMixin],
  components: {},
  data: () => ({
    modalName: 'modal--takeover',
  }),
  computed: {
    ...mapState({
      detail: (state) => state.takeover.detail,
    }),
  },
  methods: {
    close() {
      this.$modal.hide(this.modalName);
    },
    beforeClose() {
      Cookies.set('belirumaTakeover', true, { expires: 1 });
    },
    openLink() {
      this.close();
      this.$router.push(`/banner/detail/${this.detail.uuid}`);
    },
  },
};
</script>

<style lang="scss">
@import '../../../assets/scss/utils/variables';
.modal--takeover {
  .v--modal {
    background-color: transparent;
    box-shadow: none;
    @media #{$phones} {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .close--button {
    position: absolute;
    top: 0;
    right: 0;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: $color-white;
    padding: 8px;
    box-shadow: $box-shadow-input;
    cursor: pointer;
  }
  .main--image {
    display: flex;
    justify-content: center;
    padding: 0 12px;
    @media #{$phones} {
      padding: 24px 12px;
    }
    img {
      border-radius: 16px;
      width: 100%;
      max-width: calc(100vw - 48px);
      object-fit: cover;
      box-shadow: $box-shadow-input;
      cursor: pointer;
    }
  }
  .main--button {
    position: absolute;
    bottom: 16px;
    display: flex;
    justify-content: center;
    width: 100%;
    .btn {
      box-shadow: $box-shadow-input;
    }
  }
}
</style>
